@keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
	transform: translatey(-20px);
  }
  100% {
	transform: translatey(0px);
  }
}

.flutuantWrapper {
  width: 50%;
  height: auto;
  box-sizing: border-box;
  overflow: hidden;
  margin: 0 auto;
  transform: translatey(0px);
  animation: float 6s ease-in-out infinite;
}

.houseImg {
  width: 100%;
  max-width: 660px;
  height: auto;
  margin: 0 auto;
}

@media screen and (max-width: 768px) {
  .flutuantWrapper {
    width: 70%;
  }
}

@media screen and (max-width: 320px) {
  .flutuantWrapper {
    width: 80%;
  }
}
